<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_task") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateTask"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const projectId = ref(route.params?.projectId);

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const partners = computed(() => store.state.partner.partners);
    const maintenancePersonnel = computed(
      () => store.state.user.maintenancePersonnel
    );
    const task = computed(() => store.state.task.task);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.task.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const statuses = ref([
      { name: t("status.todo"), value: "todo" },
      { name: t("status.doing"), value: "doing" },
      { name: t("status.done"), value: "done" },
    ]);

    const partner = ref(null);

    const formElements = ref(null);

    const setFormElements = () => {
      if (projectId.value || task.value.projectId) {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "name",
            label: "name",
            rules: ["isRequired"],
            type: "text",
            value: task.value.name,
            variable: "name",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "datepicker",
            id: "startDate",
            label: "start_date",
            rules: ["isRequired"],
            textVariant: "white",
            value: task.value.startDate,
            variable: "startDate",
            variant: "indigo",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "datepicker",
            id: "endDate",
            label: "end_date",
            rules: ["isRequired"],
            textVariant: "white",
            value: task.value.endDate,
            variable: "endDate",
            variant: "indigo",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "status",
            label: "status",
            optionLabel: ["name"],
            options: statuses.value,
            rules: ["isRequired"],
            value: task.value.status,
            variable: "status",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "partner",
            label: "partner_contact",
            noneOption: true,
            optionLabel: ["name"],
            options: partners.value,
            rules: [],
            value: partner.value,
            variable: "partner",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "maintenancePerson",
            label: "maintenance_person",
            optionLabel: ["firstName", "lastName"],
            options: maintenancePersonnel.value,
            rules: [],
            value: task.value.maintenancePersonId,
            variable: "maintenancePersonId",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: company.value.primaryText,
            type: "submit",
            variant: company.value.primary,
          },
        ];
      } else {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "name",
            label: "name",
            rules: ["isRequired"],
            type: "text",
            value: task.value.name,
            variable: "name",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "datepicker",
            id: "startDate",
            label: "start_date",
            rules: ["isRequired"],
            textVariant: "white",
            value: task.value.startDate,
            variable: "startDate",
            variant: "indigo",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "datepicker",
            id: "endDate",
            label: "end_date",
            rules: ["isRequired"],
            textVariant: "white",
            value: task.value.endDate,
            variable: "endDate",
            variant: "indigo",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "status",
            label: "status",
            optionLabel: ["name"],
            options: statuses.value,
            rules: ["isRequired"],
            value: task.value.status,
            variable: "status",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "estate",
            label: "estate_name",
            optionLabel: ["name"],
            options: estates.value,
            rules: ["isRequired"],
            value: task.value.estateId,
            variable: "estateId",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "partner",
            label: "partner_contact",
            noneOption: true,
            optionLabel: ["name"],
            options: partners.value,
            rules: [],
            value: partner.value,
            variable: "partner",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "maintenancePerson",
            label: "maintenance_person",
            optionLabel: ["firstName", "lastName"],
            options: maintenancePersonnel.value,
            rules: [],
            value: task.value.maintenancePersonId,
            variable: "maintenancePersonId",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "save",
            textVariant: company.value.primaryText,
            type: "submit",
            variant: company.value.primary,
          },
        ];
      }
    };

    const updateTask = (formData) => {
      const data = {
        name: formData.name,
        startDate: formData.startDate,
        endDate: formData.endDate,
        status: formData.status,
        estateId: formData.estateId || null,
        maintenancePersonId: formData.maintenancePersonId,
      };
      store.dispatch("task/updateTask", { data, taskId: task.value.id });
    };

    onMounted(() => {
      if (!estates.value.length) {
        store.dispatch("estate/getEstates", company.value.id);
      }
      store.dispatch("task/getTask", route.params.taskId);
      store.dispatch("partner/getPartners");
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    watch(
      () => partner.value,
      (partner) => {
        store.dispatch("user/getMaintenancePersonnel", partner.id);
      }
    );

    watch(
      () => partners.value,
      (partners) => {
        if (partners.length) {
          setFormElements();
        }
      }
    );

    watch(
      () => maintenancePersonnel.value,
      (maintenancePersonnel) => {
        if (maintenancePersonnel.length) {
          setFormElements();
        }
      }
    );

    return {
      formElements,
      statuses,
      t,
      updateTask,
    };
  },
};
</script>
